import React, { Component } from 'react';
import Button from "../layout/content/Button";
import Modal from "react-modal";

class CategoryEdit extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        
        this.state = ({
            categoryName: ""
        })
    }

    componentDidMount() {
        Modal.setAppElement('body');
    }
    
    categoryName = (event:any) => {
        this.setState({
            categoryName: event.target.value
        });
    }

    public render() {
        let value = this.state.categoryName ? this.state.categoryName : this.props.categoryName;
        return (
            <Modal isOpen={this.props.show} className="modal-small">
                <form onSubmit={this.props.categoryUpdateHandler}>
                    <div className="form-group">
                        <label htmlFor="updateCategory">Neuer Kategoriename</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="updateCategory" 
                            name="updateCategory"
                            value={value}
                            onChange={this.categoryName}
                            required
                            />
                    </div>
                    <div className="form-group text-right">
                        <Button
                            faClass="save"
                            btnClass="btn btn-secondary update"
                            title="aktualisieren"
                            display="aktualisieren"
                        />
                        <Button
                            faClass="ban"
                            btnClass="btn btn-secondary cancel"
                            title="abbrechen"
                            display="abbrechen"
                            clickHandler={this.props.categoryUpdateCancelHandler}
                        />
                    </div>
                </form>
            </Modal>
        )
    }
}

export interface IProps {
    id: string
    categoryUpdateHandler: any
    categoryUpdateCancelHandler: any
    show: boolean
    categoryName: string
}

export interface IState {
    categoryName: string
}

export default CategoryEdit;