import React, { Component, FormEvent } from 'react';

class CustomerNew extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            customerSalutation: "",
            customerFirstname: "",
            customerLastname: "",
            buttonIcon: "save",
            button: "speichern"
        }
    }

    async componentDidMount() {
        try {
            
        } catch(error) {
            console.log(error);
        }
    }

    customerSalutationChangeHandler = (event:any) => {
        this.setState({
            customerSalutation: event.target.value
        });
    }

    customerFirstnameChangeHandler = (event:any) => {
        this.setState({
            customerFirstname: event.target.value
        });
    }

    customerLastnameChangeHandler = (event:any) => {
        this.setState({
            customerLastname: event.target.value
        });
    }

    public render() {
        let btnClass = "btn btn-secondary " + this.state.buttonIcon;
        return (
            <div>
                <h5>Neue Kunde</h5>

                <div className="container mb-5">
                    <form onSubmit={this.props.customerSaveHandler}>
                        <input type="hidden" name="customerNumber" id="customerNumber" value={this.props.customerNumber} />
                        <div className="form-group">
                            <label htmlFor="customerSalutation">
                                Anrede
                            </label>
                            <select
                                name="customerSalutation"
                                id="customerSalutation"
                                className="form-control"
                                onChange={this.customerSalutationChangeHandler}
                                required
                                >
                                <option value="">bitte wählen ...</option>
                                <option value="Herr">Herr</option>
                                <option value="Frau">Frau</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="customerFirstname">
                                Vorname
                            </label>
                            <input type="text" className="form-control" name="customerFirstname" id="customerFirstname" onChange={this.customerFirstnameChangeHandler} required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="customerLastname">
                                Nachname
                            </label>
                            <input type="text" className="form-control" name="customerLastname" id="customerLastname" onChange={this.customerLastnameChangeHandler} required/>
                        </div>
                        <div className="form-group mb-0 text-right">
                            <button type="submit" className={btnClass}>{this.state.button}</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export interface IProps {
    customerNumber: number
    customerSaveHandler: any
    customers: Array<{
        customerNumber: number
        firstname: string
        lastname: string
    }>;
}

export interface IState {
    customerSalutation: string
    customerFirstname: string
    customerLastname: string
    buttonIcon: string
    button: string
}

export default CustomerNew;