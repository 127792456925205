import * as React from 'react';
import Button from "../layout/content/Button";

class CategoryNew extends React.Component<IProps, IState> {

    public render() {
        let btnClass = "btn btn-secondary " + this.props.buttonIcon;
        return (
            <div>
                <h5>Neue Kategorie</h5>

                <div className="container mb-5">
                    <form onSubmit={this.props.categorySaveHandler} id="categorySave">
                        <div className="form-group">
                            <label htmlFor="categoryName">
                                Kategoriename
                            </label>
                            <input type="text" 
                                className="form-control" 
                                name="categoryName" 
                                id="categoryName"
                                value={this.props.value}
                                onChange={this.props.categoryExistsHandler}
                                required
                                />
                        </div>
                        <div className="form-group mb-0 text-right">
                            <Button
                                btnClass={btnClass}
                                faClass={this.props.buttonIcon}
                                display={this.props.buttonSaveValue}
                                disabled={this.props.disabled}
                            />
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export interface IProps {
    categorySaveHandler: any,
    categoryExistsHandler: any,
    buttonIcon: string,
    buttonSaveValue: string,
    disabled?: boolean,
    value?: string
}

export interface IState {

}

export default CategoryNew;