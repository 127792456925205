import * as React from 'react';
import Info from './Info';
import CustomerName from '../customer/CustomerName';
import DateFormatter from '../layout/content/DateFormatter';
import Button from "../layout/content/Button";

class AssignmentsByYear extends React.Component<IProps, IState> {

    constructor($props: IProps) {
        super($props);
        this.state = {
            offersByYear: [],
            assignmentStates: []
		};
    }

    async componentDidMount() {
		try {
            let year = this.props.year;

            let r = await fetch('/api/assignments-by-year/' + year + '/2');
            let offersByYear = await r.json();

            this.setState({
                offersByYear
            });
		} catch (error) {
			console.log(error);
		}
    }
    
    async componentDidUpdate() {
		try {
            let year = this.props.year;
            
            let r = await fetch('/api/assignments-by-year/' + year + '/2');
            let offersByYear = await r.json();

            this.setState({
                offersByYear
            });
		} catch (error) {
			console.log(error);
		}
    }

    public render() {
        return (
            <div>
                <h5>Aufträge des Jahres {this.props.year}</h5>
                <div className="list-group">
                    {this.state.offersByYear.map((offer:any, key:number) => {
                        return (
                            <div className="list-group-item mb-2" key={key}>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <Info label="Auftragsnummer" value={offer.assignmentNumber} />
                                        <Info label="Auftragsdatum" value={<DateFormatter
                                            date={offer.assignmentCreateDate}
                                        />} />
                                    </div>
                                    <div className="col-lg-4">
                                        <Info label="Kundenummer" value={offer.assignmentCustomerNumber} />
                                        <Info label="Kunde" value={<CustomerName
                                            customerNumber={offer.assignmentCustomerNumber}
                                        />} />
                                    </div>
                                    <div className="col-lg-4 text-right">
                                        <Button
                                            faClass="fa fa-print"
                                            title="Angebot drucken"
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export interface IProps {
    year: string | undefined,
    changeAssignmentNumberHandler: any,
    assignmentNumber: number
}

export interface IState {
    offersByYear: Array<{
        id: number,
        assignmentNumber: number,
        assignmentNextStep: number,
        assignmentNextStepYear: string,
    }>;
    assignmentStates: Array<{
        id: number,
        assignmentNumber: number,
        assignmentNextStep: number,
        assignmentNextStepYear: string,
    }>;
}

export default AssignmentsByYear;
