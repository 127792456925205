import React, { Component, useReducer } from 'react';
import Button from '../layout/content/Button';
import CustomerEdit from './CustomerEdit';

class CustomerList extends Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
            number: 0,
            company: "",
            firstname: "",
            lastname: "",
            address: "",
            postcode: "",
            city: "",
            email: "",
            www: "",
            phone: "",
            internet: "",
            vat: "",
            iban: "",
            bic: "",
            notice: "",
            isScForum: false,
            show: false
        };
        this.customerEditHandler = this.customerEditHandler.bind(this);
    }
    
	async componentDidMount() {
        try {
            // console.log(this.props.customers);
		} catch (error) {
			console.log(error);
		}
    }

    /**
     * Setzt die ID der zu aktualisierenden Kategorie für die Update-SQL
     */
    customerUpdateParamHandler = async (props: any) => {
        try {
            let resultCustomerById = await fetch('/api/customer-by-id/' + props.id);
            let thisCustomer = await resultCustomerById.json();
            if(thisCustomer) {
                this.setState({
                    number: thisCustomer.customerNumber,
                    firstname: thisCustomer.firstname,
                    lastname: thisCustomer.lastname,
                    company: thisCustomer.company,
                    address: thisCustomer.address,
                    postcode: thisCustomer.postcode,
                    city: thisCustomer.city,
                    email: thisCustomer.email,
                    www: thisCustomer.www,
                    phone: thisCustomer.phone,
                    internet: thisCustomer.internet,
                    vat: thisCustomer.vat,
                    iban: thisCustomer.iban,
                    bic: thisCustomer.bic,
                    notice: thisCustomer.notice,
                    isScForum: thisCustomer.isScForum,
                    show: true
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    closeModalHandler = () => {
        this.setState({
            show: false
        });
    }
    
    customerEditHandler = (props: any) => {
        window.location.hash = '/produkte/bearbeiten/' + props.id;
    }

    public render() {
        return (
            <div className="list-group">
                {this.props.customers.map((customer, key) => {
                    let listGroupItemStyle = "list-group-item " + this.props.style;
                    return (
                        <div className={listGroupItemStyle} key={key}>
                            <div className="row">
                                <div className="col-lg-1 d-flex align-items-center">{customer.customerNumber}</div>
                                <div className="col-lg-6 d-flex align-items-center">{customer.lastname} {customer.firstname}</div>
                                <div className="col-lg-5 text-right">
                                    <div className="options">
                                        <Button 
                                            btnClass="delete"
                                            faClass="fa fa-trash"
                                            title="Kunden löschen"
                                            display="löschen"
                                            id={customer.customerNumber}
                                            clickHandler={this.props.customerDeleteHandler}
                                        />
                                        <Button
                                            faClass="fa fa-edit" 
                                            title="Kunde editieren"
                                            display="bearbeiten"
                                            id={customer.customerNumber}
                                            dataToggle={true}
                                            dataTarget="#customerEditModal"
                                            clickHandler={this.customerUpdateParamHandler}
                                            />
                                        <Button 
                                            btnClass="add"
                                            faClass="fa fa-plus"
                                            display="neuer Auftrag"
                                            title="Neuer Auftrag anlegen"
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <CustomerEdit
                    id="customerEditModal"
                    customerUpdateHandler={this.props.customerUpdateHandler}
                    closeModalHandler={this.closeModalHandler}
                    customerUpdateCancelHandler={this.props.customerUpdateCancelHandler}
                    customerNumber={this.state.number}
                    customerFirstname={this.state.firstname}
                    customerLastname={this.state.lastname}
                    company={this.state.company}
                    address={this.state.address}
                    postcode={this.state.postcode}
                    city={this.state.city}
                    email={this.state.email}
                    phone={this.state.phone}
                    www={this.state.www}
                    vat={this.state.vat}
                    iban={this.state.iban}
                    bic={this.state.bic}
                    notice={this.state.notice}
                    isScForum={this.state.isScForum}
                    show={this.state.show}
                />
            </div>
        );
    }
}

export interface IProps {
    customerDeleteHandler: any
    customers: Array<{
        customerNumber: number
        firstname: string
        lastname: string
    }>;
    style?: string
    closeModalHandler?: any
    customerUpdateHandler?: any
    customerUpdateCancelHandler?: any
    customerUpdateParamHandler?: any
}

export interface IState {
    number: number
    firstname: string
    lastname: string
    company: string
    address: string
    postcode: string
    city: string
    email: string
    www: string
    phone: string
    internet: string
    vat: string
    iban: string
    bic: string
    notice: string
    isScForum: boolean
    show: boolean
}

export default CustomerList;