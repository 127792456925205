import * as React from 'react';

class CustomerName extends React.Component<IProps, IState> {
    constructor($props: IProps) {
        super($props);
        this.state = {
            name: ""
		};
    }

    async componentDidMount() {
        try {
            let customerNumber = this.props.customerNumber;
            let r = await fetch('/api/customer-name/' + customerNumber);
            let customer = await r.json();
            let name = customer[0].firstname + " " + customer[0].lastname;
            
            this.setState({
                name
            });
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <span>
                {this.state.name}
            </span>
        );
    }
}

export interface IProps {
    customerNumber: number
}

export interface IState {
    name: string
}

export default CustomerName;