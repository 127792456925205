import * as React from 'react';

class ButtonConfirm extends React.Component<IProps, IState> {

    public render() {
        let btnStdClass = "btn btn-secondary ";
        let btnClass = this.props.btnClass ? btnStdClass + this.props.btnClass : btnStdClass;
        let faClass = "fa fa-" + this.props.faClass;
        let disabled = this.props.disabled ? true : false;
        let display = this.props.display ? <span>{this.props.display}</span> : "";
        return (
            <button 
                className={btnClass} 
                onClick={() => {if (window.confirm(this.props.confirmMessage)) this.props.clickHandler(this.props.id)}}
                disabled={disabled}
                >
                <i 
                    className={faClass}
                    title={this.props.title}
                    >
                    </i>
                    {display}
            </button>
        )
    }    
}

export interface IProps {
    btnClass?: string
    faClass: string
    title?: string
    display?: string
    id?: number
    listItem?: number
    clickHandler?: any
    disabled?: boolean
    confirmMessage?: string
}

export interface IState {
}

export default ButtonConfirm;