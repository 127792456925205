import React, { Component, useReducer } from 'react';
import Button from '../layout/content/Button';
import ProductEdit from './ProductEdit';
import products from '../../../server/db/products';

class ProductList extends Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
            products: [],
            productNumber: 0,
            productName: "",
            productCategory: 0,
            productDescription: "",
            show: false
        };
        this.productEditHandler = this.productEditHandler.bind(this);
    }
    
	async componentDidMount() {
		try {
            let categoryId = this.props.categoryId;
            let resultProductByCategory = await fetch('/api/products-by-category/' + categoryId);
            let products = await resultProductByCategory.json();

            this.setState({
                products
            });
		} catch (error) {
			console.log(error);
		}
    }

    /**
     * Setzt die ID der zu aktualisierenden Kategorie für die Update-SQL
     */
    productUpdateParamHandler = async (props: any) => {
        try {
            let resultProductById = await fetch('/api/product-by-id/' + props.id);
            let thisProduct = await resultProductById.json();
            if(thisProduct) {
                this.setState({
                    productNumber: thisProduct.productNumber,
                    productName: thisProduct.productName,
                    productCategory: thisProduct.productCategory,
                    productDescription: thisProduct.productDescription,
                    show: true
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    closeModalHandler = () => {
        this.setState({
            show: false
        });
    }
    
    productEditHandler = (props: any) => {
        window.location.hash = '/produkte/bearbeiten/' + props.id;
    }

    public render() {
        return (
            <div className="list-group">
                {this.state.products.map((product, key) => {
                    let listGroupItemStyle = "list-group-item " + this.props.style;
                    return (
                        <div className={listGroupItemStyle} key={key}>
                            <div className="row">
                                <div className="col-lg-7 d-flex align-items-center">{product.productName}</div>
                                <div className="col-lg-1 d-flex align-items-center">{product.productNumber}</div>
                                <div className="col-lg-4 text-right">
                                    <div className="options">
                                        <Button
                                            faClass="fa fa-edit" 
                                            title="Produkt editieren"
                                            display="bearbeiten"
                                            id={product.productNumber}
                                            dataToggle={true}
                                            dataTarget="#productEditModal"
                                            clickHandler={this.productUpdateParamHandler}
                                            />
                                        <Button 
                                            btnClass="add"
                                            faClass="fa fa-plus"
                                            display="zum Auftrag"
                                            title="Produkt zu gewähltem Auftrag hinzufügen"
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <ProductEdit
                    id="productEditModal"
                    productUpdateHandler={this.props.productUpdateHandler}
                    closeModalHandler={this.closeModalHandler}
                    productUpdateCancelHandler={this.props.productUpdateCancelHandler}
                    productNumber={this.state.productNumber}
                    productName={this.state.productName}
                    productCategory={this.state.productCategory}
                    productDescription={this.state.productDescription}
                    show={this.state.show}
                />
            </div>
        );
    }
}

export interface IProps {
    categoryId: number
    style?: string
    closeModalHandler?: any
    productUpdateHandler?: any
    productUpdateCancelHandler?: any
    productUpdateParamHandler?: any
}

export interface IState {
    productNumber: number,
    productName: string,
    productCategory: number,
    productDescription: string

    products: Array<{
        productCategory: number,
        productNumber: number,
        productName: string,
        id: number
    }>;
    show: boolean
}

export default ProductList;