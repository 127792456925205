import * as React from 'react';
import {
    BrowserRouter as Router
} from 'react-router-dom';
import NavigationItem from './NavigationItem';
import NavigationItemToggle from './NavigationItemToggle';
import AssignmentsByYear from '../assignment/AssignmentsByYear';

class Navigation extends React.Component<IProps, IState> {

    constructor($props: IProps) {
        super($props);
        this.state = {
            offerYears: [],
            assignmentYears: [],
            billYears: []
        }
    }

	async componentDidMount() {
		try {
            let rOffers = await fetch('/api/offer-years');
            let offerYears = await rOffers.json();
            this.setState({ offerYears });

            let rBills = await fetch('/api/bill-years');
			let billYears = await rBills.json();
            this.setState({ billYears });

            let rAssignments = await fetch('/api/assignment-years');
			let assignmentYears = await rAssignments.json();
            this.setState({ assignmentYears });
		} catch (error) {
			console.log(error);
		}
    }
    
    render() {
        return (
            <nav>
                <div className="list-group">
                    <NavigationItem
                        href="/"
                        value="Dashboard"
                        icon=""
                    />
                    <NavigationItemToggle
                        href="#collapseProducts"
                        value="Produkte"
                        category="produkte"
                        changeCategory={this.props.changeCategoryHandler}
                    />
                    <div className="collapse list-group-submenu" id="collapseProducts">
                        <NavigationItem
                            href="/produkte/anzeigen"
                            value="Anzeigen"
                        />
                        <NavigationItem
                            href="/kategorien"
                            value="Kategorien"
                        />
                    </div>
                    <NavigationItemToggle
                        href="#collapseCustomers"
                        value="Kunden"
                        category="kunden"
                        changeCategory={this.props.changeCategoryHandler}
                    />
                    <div className="collapse list-group-submenu" id="collapseCustomers">
                        <NavigationItem
                            href="/kunden/anzeigen"
                            value="Anzeigen"
                        />
                    </div>
                    <NavigationItemToggle
                        href="#collapseOffers"
                        value="Angebote"
                        category="angebote"
                        changeCategory={this.props.changeCategoryHandler}
                    />
                    <div className="collapse list-group-submenu" id="collapseOffers">
                        <div>
                            <NavigationItem
                                href="neues-angebot"
                                value="Neues Angebot"
                                icon="fa fa-plus"
                                changeYear={this.props.changeYearHandler}
                            />
                        </div>
                        {this.state.offerYears.map(year => {
                            let route = "/angebote/" + year["aYear"];
                            return (
                                <div key={year["aYear"]}>
                                    <NavigationItem
                                        href={route}
                                        value={year["aYear"]}
                                        icon="fa fa-calendar"
                                        changeYear={this.props.changeYearHandler}
                                    />
                                </div>
                            )
                        })}
                    </div>
                    <NavigationItemToggle
                        href="#collapseAssignments"
                        value="Aufträge"
                        category="auftraege"
                        changeCategory={this.props.changeCategoryHandler}
                    />
                    <div className="collapse list-group-submenu" id="collapseAssignments">
                        {this.state.assignmentYears.map(year => {
                            let route = "/auftraege/" + year["aYear"];
                            return (
                                <div key={year["aYear"]}>
                                    <NavigationItem
                                        href={route}
                                        value={year["aYear"]}
                                        icon="fa fa-calendar"
                                        changeYear={this.props.changeYearHandler}
                                    />
                                </div>
                            )
                        })}
                    </div>
                    <NavigationItemToggle
                        href="#collapseBills"
                        value="Rechnungen"
                        category="rechnungen"
                        changeCategory={this.props.changeCategoryHandler}
                    />
                    <div className="collapse list-group-submenu" id="collapseBills">
                        {this.state.billYears.map(year => {
                            let route = "/rechnungen/" + year["aYear"];
                            return (
                                <div key={year["aYear"]}>
                                    <NavigationItem
                                        href={route}
                                        value={year["aYear"]}
                                        icon="fa fa-calendar"
                                        changeYear={this.props.changeYearHandler}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </nav>
        );
    }
}

export interface IProps {
    changeYearHandler: number,
    changeCategoryHandler: string,
    category: string
}

export interface IState {
    offerYears: Array<{
        assignmentCreateDate: string
        aYear: string
    }>
    assignmentYears: Array<{
        assignmentCreateDate: string
        aYear: string
    }>
    billYears: Array<{
        assignmentCreateDate: string
        aYear: string
    }>
}

export default Navigation;