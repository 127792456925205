import * as React from 'react';

interface IProps {

}

class Dashboard extends React.Component<IProps> {
    public render() {
        return (
            <div>
                Dashboard
            </div>
        );
    }
}

export default Dashboard;