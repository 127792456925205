import * as React from 'react';
import url from 'url';

class NavigationItemToggle extends React.Component<IProps> {

    render() {
        let visible = this.props.href ? "list-group-item" : "d-none";
        let expanded = false;
        if(this.props.category) {
            expanded = url.parse(window.location.href).href.split("/").indexOf(this.props.category) != -1 ? true : false;
        }

        return (
            <a href={this.props.href} 
                data-toggle="collapse" 
                aria-expanded={expanded} 
                className={visible} 
                onClick={() => this.props.changeCategory}>
                {this.props.value}
            </a>
        );
    }
}

export interface IProps {
    href: string,
    value: string,
    category?: string,
    changeCategory?: any
}

export default NavigationItemToggle;