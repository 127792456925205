import * as React from 'react';
import Navigation from '../../navigation/Navigation';

class Sidebar extends React.Component<IProps, IState> {
    public render() {
        return (
            <div className="sidebar">
                <Navigation
                    changeYearHandler={this.props.changeYearHandler}
                    changeCategoryHandler={this.props.changeCategoryHandler}
                    category={this.props.category}
                />
            </div>
        );
    }    
}

export interface IProps {
    changeYearHandler: any,
    changeCategoryHandler: any,
    category: string
}

export interface IState {

}

export default Sidebar;