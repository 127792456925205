import React, { Component } from 'react';
import {
    HashRouter as Router,
} from 'react-router-dom';
import url from 'url';

import Sidebar from './elements/layout/sidebar/Sidebar';
import Content from './elements/layout/content/Content';
import CategoryView from './elements/category/CategoryView';

class App extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            category: "",
            year: url.parse(window.location.href).href.split("/").pop(),
            assignmentNumber: 0
        }
        this.changeYearHandler = this.changeYearHandler.bind(this);
        this.changeCategoryHandler = this.changeCategoryHandler.bind(this);
        this.changeAssignmentNumberHandler = this.changeAssignmentNumberHandler.bind(this);
    }

    changeCategoryHandler = (category: string) => {
        this.setState({
            category: category
        });
    }

    changeYearHandler = (year: string) => {
        this.setState({
            year: year
        });
    }

    changeAssignmentNumberHandler = (assignmentNumber: number) => {
        this.setState({
            assignmentNumber: assignmentNumber
        });
    }
    
	render() {
        return (
            <main className="">
                <div className="row">
                    <Router>
                        <div className="col-lg-3">
                            <Sidebar
                                changeYearHandler={this.changeYearHandler}
                                changeCategoryHandler={this.changeCategoryHandler}
                                category={this.state.category}
                                />
                        </div>
                        <div className="col-lg-9 content">
                            <Content
                                year={this.state.year}
                                changeAssignmentNumberHandler={this.changeAssignmentNumberHandler}
                                assignmentNumber={this.state.assignmentNumber}
                            />
                        </div>
                    </Router>
                </div>
			</main>
		);
	}
}

export interface IProps {

}

export interface IState {
    category: string,
    year: string | undefined,
    assignmentNumber: number
}

export default App;
