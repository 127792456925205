import React, { Component, FormEvent } from 'react';

class ProductNew extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.productSaveHandler = this.productSaveHandler.bind(this);
    }

    state = {
        productName: "",
        productDescription: "",
        buttonIcon: "save",
        button: "speichern"
    }

    productSaveHandler = (event:any) => {
        event.preventDefault();
        let self = this;

        const product = {
            productName: this.state.productName,
            productDescription: this.state.productDescription,
        }
        this.setState({
            button: "wird gespeichert"
        });

        fetch('/api/product-save', {
            method: 'post',
            body: JSON.stringify(product)
        })
        .then(function(response) {
            self.setState({
                button: "gespeichert"
            });
        })
        .catch(function(error) {
            console.log(error);
        })
    }

    productNameChangeHandler = (event:any) => {
        this.setState({
            productName: event.target.value
        });
    }

    productDescriptionChangeHandler = (event:any) => {
        this.setState({
            productDescription: event.target.value
        });
    }

    public render() {
        let btnClass = "btn btn-secondary " + this.state.buttonIcon;
        return (
            <div>
                <h5>Neues Produkt</h5>

                <div className="container mb-5">
                    <form onSubmit={this.productSaveHandler}>
                        <div className="form-group">
                            <label htmlFor="productName">
                                Produktname
                            </label>
                            <input type="text" className="form-control" name="productName" id="productName" onChange={this.productNameChangeHandler} required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="productCategories">
                                Produktkategorie
                            </label>
                            <select name="productCategories" id="productCategories" className="form-control" required>
                                <option>bitte wählen ...</option>
                                {this.props.categories.map((category, key) => {
                                    return (
                                        <option key={key}>
                                            {category.categoryName}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="productDescription">
                                Produktbeschreibung
                            </label>
                            <textarea className="form-control" name="productDescription" id="productDescription" onChange={this.productDescriptionChangeHandler}></textarea>
                        </div>
                        <div className="form-group mb-0 text-right">
                            <button type="submit" className={btnClass}>{this.state.button}</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export interface IProps {
    categories: Array<{
        id?: number,
        categoryName: string
    }>;
}

export interface IState {
}

export default ProductNew;