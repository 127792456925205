import React, { Component } from 'react';
import Modal from "react-modal";

class CustomerEdit extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
        Modal.setAppElement('body');
    }

    isScForumChange = () => {
        
    }
    
    public render() {
        return (
            <Modal isOpen={this.props.show} className="modal-large">
                <form action="">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="customerNumber">Kundennummer</label>
                                <input type="text" name="customerNumber" id="customerNumber" className="form-control" defaultValue={this.props.customerNumber} disabled />
                            </div>
                            <div className="form-group">
                                <label htmlFor="company">Firma</label>
                                <input type="text" name="company" id="company" className="form-control" defaultValue={this.props.company} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="customerName">Vorname</label>
                                <input type="text" name="customerFirstname" id="customerFirstname" className="form-control" defaultValue={this.props.customerFirstname} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="customerDescription">Nachname</label>
                                <input type="text" name="customerLastname" id="customerLastname" className="form-control" defaultValue={this.props.customerLastname} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="address">Adresse</label>
                                <input type="text" name="address" id="address" className="form-control" defaultValue={this.props.address} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="postcode">Postleizahl</label>
                                <input type="text" name="postcode" id="postcode" className="form-control" defaultValue={this.props.postcode} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="city">Stadt</label>
                                <input type="text" name="city" id="city" className="form-control" defaultValue={this.props.city} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">E-Mail</label>
                                <input type="text" name="email" id="email" className="form-control" defaultValue={this.props.email} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Telefon</label>
                                <input type="text" name="phone" id="phone" className="form-control" defaultValue={this.props.phone} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="www">Internet</label>
                                <input type="text" name="www" id="www" className="form-control" defaultValue={this.props.www} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="vat">VAT</label>
                                <input type="text" name="vat" id="vat" className="form-control" defaultValue={this.props.vat} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="iban">IBAN</label>
                                <input type="text" name="iban" id="iban" className="form-control" defaultValue={this.props.iban} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="bic">BIC</label>
                                <input type="text" name="bic" id="bic" className="form-control" defaultValue={this.props.bic} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="notice">Notiz</label>
                                <textarea name="notice" id="notice" className="form-control" defaultValue={this.props.notice} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="isScForum">ScForum Mitglied?</label>
                                <input type="checkbox" name="isScForum" id="isScForum" className="form-control" checked={this.props.isScForum} onChange={this.isScForumChange} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-0 text-right float-right">
                        <button type="submit" className="btn btn-secondary save">Aktualisieren</button>
                    </div>
                    <div className="form-group mb-0 text-right float-right">
                        <button type="button" className="btn btn-secondary cancel" onClick={this.props.closeModalHandler}>Schließen</button>
                    </div>
                </form>
            </Modal>
        );
    }
}

export interface IProps {
    id: string
    customerUpdateHandler: any
    customerUpdateCancelHandler: any
    customerNumber: number
    company: string
    customerFirstname: string
    customerLastname: string
    address: string
    postcode: string
    city: string
    email: string
    phone: string
    www: string
    vat: string
    iban: string
    bic: string
    notice: string
    isScForum: boolean
    show: boolean
    closeModalHandler: any
}

export interface IState {
}

export default CustomerEdit;