import React, { Component } from 'react';
import DateFormatter from '../layout/content/DateFormatter';

class Info extends Component<IProps, IState> {
    render() {
        return (
            <div className="info">
                <label>{this.props.label}:</label>
                <span>{this.props.value}</span>
            </div>
        );
    }
}

export interface IProps {
    label: string,
    value: any
}

export interface IState {

}

export default Info;