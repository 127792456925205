import React, { Component } from 'react';
import ButtonConfirm from '../layout/content/ButtonConfirm';
import Button from '../layout/content/Button';
import CategoryEdit from './CategoryEdit';

class CategoryList extends Component<IProps, IState> {

    public render() {
        return (
            <div>
                <h5>Produkt Kategorie</h5>
                <div className="list-group">
                    {this.props.categories.map((category:any, key:number) => {
                        return (
                            <div className="list-group-item" key={key}>
                                <div className="row">
                                    <div className="col-lg-6 d-flex align-items-center">
                                        {category.categoryName}
                                    </div>
                                    <div className="col-lg-6 text-right">
                                        <div className="options">
                                            <Button 
                                                faClass="edit"
                                                title="Kategorie bearbeiten"
                                                display="bearbeiten"
                                                dataToggle={true}
                                                dataTarget="#categoryEditModal"
                                                id={category.id}
                                                clickHandler={this.props.categoryUpdateParamHandler}
                                            />
                                            <ButtonConfirm 
                                                btnClass="delete"
                                                faClass="trash" 
                                                title="Kategorie löschen"
                                                display="löschen"
                                                confirmMessage="Bitte bestätigen Sie, dass Sie diese Kategorie löschen wollen"
                                                id={category.id}
                                                listItem={key}
                                                clickHandler={this.props.categoryDeleteHandler}
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <CategoryEdit 
                        id="categoryEditModal"
                        categoryUpdateHandler={this.props.categoryUpdateHandler}
                        categoryUpdateCancelHandler={this.props.categoryUpdateCancelHandler}
                        categoryName={this.props.categoryName}
                        show={this.props.show}
                    />
                </div>
            </div>
        );
    }
}

export interface IProps {
    categories: any
    categoryDeleteHandler: any
    categoryUpdateHandler: any
    categoryUpdateParamHandler: any
    categoryUpdateCancelHandler: any
    show: boolean
    categoryName: string
}

export interface IState {
}

export default CategoryList;