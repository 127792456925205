import * as React from 'react';

class OffersNew extends React.Component<IProps, IState> {

    constructor($props: IProps) {
        super($props);
        this.state = {
            defaultDate: null
		};
    }

    async componentDidMount() {
		try {
            this.setState({
                defaultDate: new Date()
            })
		} catch (error) {
			console.log(error);
		}
    }
    
    public render() {
        return (
            <div>
                <h5>Neues Angebot anlegen</h5>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <form action="" method="post">
                                <input 
                                    type="date" 
                                    name="offerDate" 
                                    className="form-control"
                                    defaultValue={this.state.defaultDate}
                                    />
                                <input type="submit" value="anlegen" className="btn btn-success" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export interface IProps {
}

export interface IState {
    defaultDate: any
}

export default OffersNew;
