import React, { Component } from 'react';
import CustomerList from './CustomerList';
import CustomerNew from './CustomerNew';
import object from '../../../server/db/object';

class ProductView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            customers: [],
            categories: [],
            customerNumber: 0,
            customerNumberNext: 0,
            customerSalutation: "",
            customerFirstname: "",
            customerLastname: "",
            buttonIcon: "save",
            button: "speichern"
        }

        this.customerSaveHandler = this.customerSaveHandler.bind(this);
        this.customerDeleteHandler = this.customerDeleteHandler.bind(this);
    }

    async componentDidMount() {
        try {
            let resultCustomers = await fetch('/api/customers');
            let customers = await resultCustomers.json();

            let r = await fetch('/api/get-next-customerNumber');
            let customerNumberNext = await r.json();

            this.setState({
                customers: customers,
                customerNumberNext: customerNumberNext
            });
		} catch (error) {
			console.log(error);
		}
    }

    customerSaveHandler = async (event:any) => {
        event.preventDefault();
        let self = this;

        const customer = {
            customerNumber: event.target.customerNumber.value,
            customerSalutation: event.target.customerSalutation.value,
            customerFirstname: event.target.customerFirstname.value,
            customerLastname: event.target.customerLastname.value,
        }

        try {
            this.setState({
                button: "wird gespeichert"
            });
    
            fetch('/api/customer-save', {
                method: 'post',
                body: JSON.stringify(customer)
            })
            .then(function(response) {
                self.setState({
                    button: "gespeichert"
                });
            })
            .catch(function(error) {
                console.log(error);
            })

            let r = await fetch('/api/get-next-customerNumber');
            let customerNumberNext = await r.json();

            let result = await fetch('/api/customers');
            let list = await result.json();
            self.setState({
                customers: list,
                customerNumberNext: customerNumberNext
            });
        } catch(error) {
            console.log(error);
        }

    }

    customerDeleteHandler = async (props: any) => {
        var self = this;
        var confirm = window.confirm("Wollen Sie diesen Benutzer wirklich löschen");
        if(confirm) {
            try {
                await fetch('/api/customer-delete/' + props.id, {
                    method: 'post',
                })
                .then(function(response) {
                    // console.log(response);
                })
                .catch(function(error) {
                    console.log(error);
                })

                let result = await fetch('/api/customers');
                let list = await result.json();
                self.setState({
                    customers: list,
                });
            } catch (error) {
                console.log(error);
            }
        }
    }
    
    public render() {
        return (
            <div className="row">
                <div className="col-6">
                    <CustomerNew
                        customerSaveHandler={this.customerSaveHandler}
                        customers={this.state.customers}
                        customerNumber={this.state.customerNumberNext}
                    />
                </div>
                <div className="col-6">
                </div>
                <div className="col-12">
                    <CustomerList
                        customerDeleteHandler={this.customerDeleteHandler}
                        customers={this.state.customers}
                    />
                </div>
            </div>
        );
    }
}

export interface IProps {
    
}

export interface IState {
    customerNumber: number
    customerNumberNext: number
    customerSalutation: string
    customerFirstname: string
    customerLastname: string
    buttonIcon: string
    button: string

    categories: Array<{
        id?: number,
        categoryName: string
    }>;
    customers: Array<{
        customerNumber: number
        firstname: string
        lastname: string
    }>;
}

export default ProductView;