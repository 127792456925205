import * as React from 'react';
import ProductList from './ProductList';
import ProductView from './ProductView';

class ProductListGroup extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            productCategories: [],
        }
    }

    async componentDidMount() {
		try {
			let resultProductCaegories = await fetch('/api/product-categories');
			let productCategories = await resultProductCaegories.json();
			this.setState({
                productCategories
            });
		} catch (error) {
			console.log(error);
		}
    }
    
    public render() {
        return (
            <div>
                {this.state.productCategories.map(category => {
                    return (
                        <div key={category.id}>
                            <h5>{category.categoryName}</h5>
                            <ProductList
                                categoryId={category.id}
                                style={category.categoryName.toLowerCase()}
                            />
                        </div>
                    )
                })}
            </div>
        )
    }
}

export interface IProps {

}

export interface IState {
    productCategories: Array<{
        id: number,
        categoryName: string
    }>;
}

export default ProductListGroup;