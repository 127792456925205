import * as React from 'react';
import url from 'url';
import {
    Link
} from 'react-router-dom';

class NavigationItem extends React.Component<IProps, IState> {
    
    public static defaultProps = {
        icon: "fa fa-bars"
    };

    render() {
        let icon = this.props.icon ? this.props.icon : "";
        let className = "";
        let visible = this.props.href ? "list-group-item" : "d-none";
        let active = url.parse(window.location.href).href.indexOf(this.props.href) ? "active" : "";

        className = visible + " " + active;

        return (
            <Link to={this.props.href} 
                className={className} 
                onClick={() => this.props.changeYear ? this.props.changeYear(this.props.value) : null}>
                <i className={icon}></i>
                {this.props.value}
            </Link>
        );
    }
}

interface IProps {
    href: string,
    value: string,
    icon?: string,
    changeYear?: any
}

interface IState {
    
}

export default NavigationItem;