import React, { Component } from 'react';

class DateFormatter extends Component<IProps, IState> {
    render() {
        return (
            <span>{this.setFormat(this.props.date, this.props.format)}</span>
        );
    }

    public setFormat(date: string, format?: string) {
        try {
            var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
    
            switch(format) {
                case "dd.mm": {
                    return [day, month].join('.');
                }
                case "mm.yy": {
                    return [month, year].join('.');
                }
                case "yy": {
                    return [year].join('.');
                }
                default: {
                    return [day, month, year].join('.');
                }
            }
        } catch (error) {
            console.log(error)            ;
        }
    }
}

export interface IProps {
    date: string,
    format?: string
}

export interface IState {
}

export default DateFormatter;