import React, { Component } from 'react';
import Modal from "react-modal";

class ProductEdit extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            categories: [],
            selectValue: 5
        }
    }

    async componentDidMount() {
        Modal.setAppElement('body');
        try {
            let r = await fetch('/api/product-categories');
            let categories = await r.json();
            this.setState({ categories });
        } catch (error) {
            console.log(error);
        }
    }

    handleChange = (evt: any) => {
        this.setState({
            selectValue: evt.target.value
        });
        console.log(this.state.selectValue);
    }

    public render() {
        return (
            <Modal isOpen={this.props.show} className="modal-middle">
                <form action="">
                    <div className="form-group">
                        <label htmlFor="productNumber">Produktnummer</label>
                        <input type="text" name="productNumber" id="productNumber" className="form-control" defaultValue={this.props.productNumber} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="productName">Produktname</label>
                        <input type="text" name="productName" id="productName" className="form-control" defaultValue={this.props.productName} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="productCategories">
                            Produktkategorie
                        </label>
                        <select 
                            name="productCategories" 
                            id="productCategories" 
                            className="form-control"
                            value={this.props.productCategory} 
                            onChange={this.handleChange} 
                            required>
                            <option>bitte wählen ...</option>
                            {this.state.categories.map((category, key) => {
                                return (
                                    <option value={category.id} key={key}>
                                        {category.categoryName}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="productDescription">Produktbeschreibung</label>
                        <textarea name="productDescription" id="productDescription" className="form-control" defaultValue={this.props.productDescription} />
                    </div>
                    <div className="form-group mb-0 text-right float-right">
                        <button type="submit" className="btn btn-secondary save">Aktualisieren</button>
                    </div>
                    <div className="form-group mb-0 text-right float-right">
                        <button type="button" className="btn btn-secondary cancel" onClick={this.props.closeModalHandler}>Schließen</button>
                    </div>
                </form>
            </Modal>
        );
    }
}

export interface IProps {
    id: string
    productUpdateHandler: any
    productUpdateCancelHandler: any
    productNumber: number,
    productName: string,
    productCategory: number,
    productDescription: string
    show: boolean
    closeModalHandler: any
}

export interface IState {
    selectValue: number
    categories: Array<{
        id?: number,
        categoryName: string
    }>;
}

export default ProductEdit;