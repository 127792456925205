import React, { Component } from 'react';
import CategoryNew from './CategoryNew';
import CategoryList from './CategoryList';
import Modal from 'react-modal';

class CategoryView extends Component<IProps, IState> {
        
    constructor(props: IProps) {
        super(props);
        this.state = {
            categories: [],
            categoryName: "",
            categoryIdToUpdate: 0,
            buttonSaveValue: "speichern",
            buttonUpdateValue: "aktualisieren",
            buttonIcon: "save",
            value: "",
            show: false,
        }

        this.categorySaveHandler = this.categorySaveHandler.bind(this);
        this.categoryUpdateHandler = this.categoryUpdateHandler.bind(this);
    }

    async componentDidMount() {
		try {
			let r = await fetch('/api/product-categories');
			let categories = await r.json();
            this.setState({ categories });
		} catch (error) {
			console.log(error);
		}
    }

    /**
     * Prüfen, ob die zu erstellende Kategorie bereits vorhanden ist
     */
    categoryExistsHandler = async (event:any) => {
        this.setState({
            categoryName: event.target.value
        });

        let r = await fetch('/api/category-exists/' + event.target.value);
        let categoryExists = await r.json();
        if(categoryExists) {
            this.setState({
                buttonIcon: "exclamation",
                buttonSaveValue: "existiert bereits",
                disabled: true
            });
        } else {
            this.setState({
                buttonIcon: "save",
                buttonSaveValue: "speichern",
                disabled: false
            });
        }
    }
    
    /**
     * Speichern einer neuen Kategorie
     */
    categorySaveHandler = (event:any) => {
        event.preventDefault();
        let self = this;

        const category = {
            categoryName: this.state.categoryName
        }

        let newCategory = {
            categoryName: this.state.categoryName
        }
        this.setState({
            categories: [
                ...this.state.categories,
                newCategory
            ],
            buttonIcon: "spinner",
            buttonSaveValue: "wird gespeichert"
        });
        fetch('/api/category-save', {
            method: 'post',
            body: JSON.stringify(category)
        })
        .then(function(response) {
            self.setState({
                buttonIcon: "check",
                buttonSaveValue: "gespeichert"
            });
        })
        .then(function() {
            self.setState({
                buttonIcon: "save",
                buttonSaveValue: "speichern",
                categoryName: ""
            });
        })
        .catch(function(error) {
            console.log(error);
        })
    }

    /**
     * Setzt die ID der zu aktualisierenden Kategorie für die Update-SQL
     */
    categoryUpdateParamHandler = async (props: any) => {
        try {
            let r = await fetch('/api/category-by-id/' + props.id);
            let categories = await r.json();
            if(categories) {
                this.setState({
                    categoryName: categories[0].categoryName
                });
            }
        } catch (error) {
            console.log(error);
        }

        this.setState({
            categoryIdToUpdate: props.id,
            show: true
        });
    }

    /**
     * Aktualisiert die selektierte Kategorie
     */
    categoryUpdateHandler = async (event:any) => {
        event.preventDefault();
        var self = this;
        
        const data = new FormData(event.target);
        const id = this.state.categoryIdToUpdate;
        const updateCategory = data.get('updateCategory');
        fetch('/api/category-update/' + id + "/" + updateCategory, {
            method: 'post',
            body: data
        })
        .then(function(response) {
        })
        .catch(function(err) {
            console.log(err);
        })
        
        let r = await fetch('/api/product-categories');
        let categories = await r.json();
        this.setState({
            categories,
            show: false
        });
    }

    /**
     * Schließt das Bearbeiten-Modal bei Klick auf abbrechen
     */
    categoryUpdateCancelHandler = () => {
        this.setState({
            show: false
        });
    }

    /**
     * Löscht die selektierte Kategorie. Dies muss vorher bestätigt werden
     */
    categoryDeleteHandler = (id: number) => {
        var self = this;
        fetch('/api/category-delete', {
            method: 'post',
            body: JSON.stringify(id)
        })
        .then(function(response) {
        })
        .catch(function(error) {
            console.log(error);
        })
        let newCategories = [...this.state.categories];
        newCategories.filter(function(category, index) {
            if(category.id == id) {
                if(index > -1) {
                    newCategories.splice(index, 1);
                }
                self.setState({
                    categories: newCategories
                });
            }
            return index;
        });
    }
    
    public render() {
        return (
            <div className="row">
                <div className="col-6">
                    <CategoryNew
                        categorySaveHandler={this.categorySaveHandler}
                        categoryExistsHandler={this.categoryExistsHandler}
                        buttonIcon={this.state.buttonIcon}
                        buttonSaveValue={this.state.buttonSaveValue}
                        disabled={this.state.disabled}
                        value={this.state.categoryName}
                        />
                </div>
                <div className="col-6"></div>
                <div className="col-12">
                    <CategoryList
                        categories={this.state.categories}
                        categoryDeleteHandler={this.categoryDeleteHandler}
                        categoryUpdateParamHandler={this.categoryUpdateParamHandler}
                        categoryUpdateHandler={this.categoryUpdateHandler}
                        categoryUpdateCancelHandler={this.categoryUpdateCancelHandler}
                        categoryName={this.state.categoryName}
                        show={this.state.show}
                    />
                </div>                
            </div>
        );
    }
}

export interface IProps {
}

export interface IState {
    categories: Array<{
        id?: number,
        categoryName: string
    }>;
    categoryName: string
    categoryIdToUpdate: number
    buttonSaveValue: string
    buttonUpdateValue: string
    buttonIcon: string
    disabled?: boolean
    value?: string
    show: boolean
}

export default CategoryView;