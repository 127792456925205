import * as React from 'react';
import {
    Switch,
    Route
} from 'react-router-dom';

import Dashboard from '../../dashboard/Dashboard';

import CustomerView from '../../customer/CustomerView';

import ProductListGroup from '../../product/ProductListGroup';
import ProductView from '../../product/ProductView';
import ProductEditView from '../../product/ProductEdit';

import CategoryView from '../../category/CategoryView';

import OffersNew from '../../assignment/OffersNew';
import OffersByYear from '../../assignment/OffersByYear';

import AssignmentsByYear from '../../assignment/AssignmentsByYear';

import BillsByYear from '../../assignment/BillsByYear';

class Content extends React.Component<IProps, IState> {
    public render() {
        return (
            <Switch>
                <Route path="/" component={Dashboard} exact/>
                
                <Route path="/produkte/anzeigen" component={ProductView} />
                {/* <Route path="/produkte/bearbeiten/:productNumber" component={ProductEditView} /> */}

                <Route path="/kategorien" component={CategoryView} />
                <Route path="/kategorien/bearbeiten/:id" component={CategoryView} />

                <Route path="/angebote/neues-angebot" component={OffersNew} />
                <Route path="/angebote/:year" render={(props) => 
                    <OffersByYear {...props} 
                        year={this.props.year}
                        changeAssignmentNumberHandler={this.props.changeAssignmentNumberHandler}
                        assignmentNumber={this.props.assignmentNumber}
                    />} />
                
                <Route path="/auftraege/:year" render={(props) => 
                    <AssignmentsByYear {...props} 
                        year={this.props.year}
                        changeAssignmentNumberHandler={this.props.changeAssignmentNumberHandler}
                        assignmentNumber={this.props.assignmentNumber}
                    />} />
                
                <Route path="/rechnungen/:year" render={(props) => 
                    <BillsByYear {...props} 
                        year={this.props.year}
                        changeAssignmentNumberHandler={this.props.changeAssignmentNumberHandler}
                        assignmentNumber={this.props.assignmentNumber}
                    />} />

                <Route path="/kunden/anzeigen" component={CustomerView} />
            </Switch>
        );
    }
}

export interface IProps {
    year: string | undefined,
    changeAssignmentNumberHandler: any,
    assignmentNumber: number
}

export interface IState {
}

export default Content;