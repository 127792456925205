import React, { Component } from 'react';

import ProductNew from '../product/ProductNew';
import ProductCategory from '../category/CategoryView';
import ProductList from './ProductList';
import ProductListGroup from './ProductListGroup';



class ProductView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            categories: [],
        }
    }

    async componentDidMount() {
        try {
            let r = await fetch('/api/product-categories');
            let categories = await r.json();
            this.setState({ categories });
        } catch (error) {
            console.log(error);
        }
    }
    
    public render() {
        return (
            <div className="row">
                <div className="col-6">
                    <ProductNew
                        categories={this.state.categories}
                    />
                </div>
                <div className="col-6">
                </div>
                <div className="col-12">
                    <ProductListGroup />
                </div>
            </div>
        );
    }
}

export interface IProps {
    
}

export interface IState {
    categories: Array<{
        id?: number,
        categoryName: string
    }>;
}

export default ProductView;